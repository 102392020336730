import React, {useEffect, useState} from 'react';
import * as sty from '../style';
import {Helmet} from 'react-helmet';
import {Icons} from '../components/icons';

const ModeDetector = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const Listener = (e) => {
        setIsDarkMode(e.matches);
    };

    useEffect(() => {
        const darkModeEvent = window.matchMedia('(prefers-color-scheme: dark)');
        darkModeEvent.addEventListener('change', Listener);
        return () => darkModeEvent.removeEventListener('change', darkModeEvent);
    }, []);
    return isDarkMode;
};

export default function Home() {
    const isDarkMode = ModeDetector();

    return (
        <sty.background>
            <Helmet>
                <meta charSet="utf-8" />
                <html lang="en" />
                <title>Home - Magicgh</title>
                <meta name="description" content="Description" />
            </Helmet>
            <sty.container>
                <sty.card>
                    <sty.avatar src="avatar.png" alt="Avatar" />
                    <sty.title>
                        <sty.name> Magicgh </sty.name>
                        <sty.key> C1CE 70CB CE8B DDC9 </sty.key>
                    </sty.title>
                </sty.card>
                <sty.navbar>
                    <sty.subtitle>Stay Hungry. Stay Foolish.</sty.subtitle>
                    <sty.icons>
                        {' '}
                        <Icons isDarkMode={isDarkMode} />{' '}
                    </sty.icons>
                </sty.navbar>
            </sty.container>
        </sty.background>
    );
}
