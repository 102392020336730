import React from 'react';
import {Social} from '../style';
import * as ri from 'react-icons/ri';
import * as im from 'react-icons/im';
import * as si from 'react-icons/si';

export const Icons = ({isDarkMode}) => {
    return (
        <>
            <Social
                href="https://github.com/magicgh"
                target="_blank"
                rel="noreferrer"
            >
                {isDarkMode ? (
                    <ri.RiGithubFill aria-label="GitHub" />
                ) : (
                    <ri.RiGithubLine aria-label="GitHub" />
                )}
            </Social>

            <Social
                href="https://blog.magicgh.com"
                target="_blank"
                rel="noreferrer"
            >
                {isDarkMode ? (
                    <si.SiMicrodotblog aria-label="Blog" />
                ) : (
                    <im.ImBlog aria-label="Blog" />
                )}
            </Social>

            <Social
                href="https://twitter.com/0bllz"
                target="_blank"
                rel="noreferrer"
            >
                {isDarkMode ? (
                    <ri.RiTwitterFill aria-label="Twitter" />
                ) : (
                    <ri.RiTwitterLine aria-label="Twitter" />
                )}
            </Social>

            <Social
                href="mailto:home@magicgh.com"
                target="_blank"
                rel="noreferrer"
            >
                {isDarkMode ? (
                    <ri.RiMailFill aria-label="Mail" />
                ) : (
                    <ri.RiMailLine aria-label="Mail" />
                )}
            </Social>
        </>
    );
};
